<template>
  <asom-slide-menu v-model="showMenu" title="Coin Flush Details">
    <section v-if="selectedTransaction" class="space-y-5 mb-10">
      <div class="space-y-8 sm:space-y-0">
        <asom-slide-item
          title="TransactionNo"
          :value="get(selectedTransaction, 'referenceNo', '-')"
        ></asom-slide-item>
        <asom-slide-item
          title="Staff"
          :value="get(selectedTransaction, 'createdBy', '-')"
        ></asom-slide-item>
        <asom-slide-item
          title="Date"
          :value="displayUtcDate(get(selectedTransaction, 'dateCreated', '-'))"
        ></asom-slide-item>
        <asom-slide-item
          title="Time"
          :value="displayUtcDate(get(selectedTransaction, 'dateCreated', '-'), 'HH:mm')"
        ></asom-slide-item>
        <asom-slide-item
          title="Purpose"
          :value="get(selectedTransaction, 'purpose', '-')"
        ></asom-slide-item>
        <asom-slide-item
          title="Method"
          :value="method"
        ></asom-slide-item>
        <asom-slide-item
          title="Remarks"
          :value="get(selectedTransaction, 'remarks', 'NIL')"
        ></asom-slide-item>
      </div>
    </section>
  </asom-slide-menu>
</template>

<script>
import get from "lodash.get";
import { displayUtcDate } from "@/helpers/dateTimeHelpers";

export default {
  props: {
    selectedTransaction: {
      type: undefined,
    },
    modelValue: {
      type: Boolean,
    },
  },
  emits: ["update:modelValue"],
  computed: {
    showMenu: {
      get() {
        return this.modelValue;
      },
      set(newValue) {
        this.$emit("update:modelValue", newValue);
      },
    },
    method() {
      const isFlushing = get(this.selectedTransaction, 'isFlushing')
      return isFlushing ? 'Flushing' : get(this.selectedTransaction, 'otherMethod');
    }
  },
  methods: {
    get,
    displayUtcDate,
  }
};
</script>
