<template>
  <section class="space-y-10">
    <QuickInfo />
    <asom-alert v-if="voidError" variant="error" :error-message="voidError" />
    <asom-alert v-if="error" variant="error" :error-message="error" />

    <article v-if="canCreateCoinFlush">
      <h3 class="subheader">
        Quick Links
      </h3>
      <br />
      <section
        class="relative z-0  rounded-md grid sm:grid-cols-1 md:grid-cols-3"
      >
        <asom-button
          variant="primary"
          outline
          rounded="none"
          @click="$router.push('coinflush/create')"
          icon="swap"
          text="New Coin Flushing Form"
        >
        </asom-button>
      </section>
    </article>

    <article>
      <h3 class="subheader">
        Coin Flushing Logs
      </h3>
      <br />
      <asom-client-table
        :data="getTableData"
        :columns="[
          'referenceNo',
          'createdBy',
          'dateCreated',
          'gtm',
          'addedToCDF',
          'action',
        ]"
        :sortableColumns="[
          'referenceNo',
          'createdBy',
          'dateCreated',
          'gtm',
          'addedToCDF',
        ]"
        :searchableDateColumns="['dateCreated']"
      >
        <template v-slot:header_createdBy>Staff</template>
        <template v-slot:referenceNo="{ data, rowData }">
          <div class="flex space-x-2">
            <asom-link
            v-if="!rowData.isVoided"
            @click="toggleSlideMenu(rowData)"
              >{{ data }}</asom-link
              >
            <span v-else class="line-through">{{ data }}</span>
            <asom-tooltip
            v-if="get(rowData, 'addedToCDF')"
            tooltip-text="Bagged in Cash Declaration"
            >
            <template #toggleText>
              <asom-badge variant="primary" class="p-1">
                <asom-icon icon="shopping-bag" size="sm" />
              </asom-badge>
            </template>
          </asom-tooltip>
        </div>
      </template>
      <template v-slot:header_dateCreated>Date</template>
      <template v-slot:header_gtm>GTM/TUK</template>
        <template v-slot:dateCreated="slotScoped">{{
          displayUtcDate(slotScoped.rowData.dateCreated)
        }}</template>
        <template v-slot:header_addedToCDF>Status</template>
        <template v-slot:addedToCDF="{ rowData }">{{
          rowData.addedToCDF ? "Bagged" : "Flush"
        }}</template>
        <template v-slot:action="{ rowData }">
          <asom-button
            v-if="!rowData.addedToCDF && !rowData.isVoided && canCreateCoinFlush"
            variant="error"
            outline
            size="sm"
            text="Cancel Entry"
            @click="confirmVoid(rowData)"
          />
        </template>
      </asom-client-table>
    </article>
    <asom-modal title="Void Log" v-model="showVoidModal" :dismissible="false">
      <p class="pt-4">
        This would void the coin flush form
        <strong>{{ get(selectedTransaction, "referenceNo") }}</strong
        >. Do you want to continue?
      </p>
      <div class="flex flex-row-reverse pt-4">
        <div class="pl-4">
          <asom-button
            @click="onVoid()"
            :disabled="isVoiding"
            :is-loading="isVoiding"
            text="OK"
          />
        </div>
        <div>
          <asom-button
            @click="showVoidModal = false"
            text="Cancel"
            variant="secondary"
          />
        </div>
      </div>
    </asom-modal>
  </section>
  <coin-flush-slide-menu
    v-model="showSlideMenu"
    :selected-transaction="selectedTransaction"
  />
</template>

<script>
import get from "lodash.get";
import { mapGetters } from "vuex";
import {
  getCoinFlushSummary,
  voidCoinFlushForm,
} from "@/services/cashManagement.service";
import { formatCurrency } from "@/helpers/numbers";
import { displayUtcDate } from "@/helpers/dateTimeHelpers";
import QuickInfo from "../../_QuickInfo.vue";
import CoinFlushSlideMenu from "./CoinFlushSlideMenu.vue";

export default {
  components: {
    QuickInfo,
    CoinFlushSlideMenu,
  },
  data() {
    return {
      isLoading: false,
      error: null,
      summary: {
        coinFlushForms: [],
      },
      selectedTransaction: null,
      showSlideMenu: false,
      showVoidModal: false,
      voidError: null,
      isVoiding: false,
    };
  },
  mounted() {
    this.loadData();
  },
  computed: {
    ...mapGetters({
      afcId: "cashManagement/stationCashAccounts/afcId",
      canCreateCoinFlush: 'auth/canCreateCoinFlush',
    }),
    getTableData() {
      return this.summary.coinFlushForms.map((row) => {
        if (row.isVoided) row.deleted = true;
        return row;
      });
    },
  },
  methods: {
    get,
    displayUtcDate,
    formatCurrency,
    async loadData() {
      this.error = null;
      this.isLoading = true;
      await this.$store.dispatch("cashManagement/loadStationCashAccounts");
      const result = await getCoinFlushSummary(this.afcId);
      if (result.success) {
        this.setData(result);
      } else {
        this.error = result.payload;
        this.$scrollTop();
      }
      this.isLoading = false;
    },
    setData(apiResult) {
      this.summary.coinFlushForms = get(
        apiResult,
        "payload.data.coinFlushForms",
        []
      );
    },
    toggleSlideMenu(transaction) {
      this.selectedTransaction = transaction;
      this.showSlideMenu = true;
    },
    confirmVoid(transaction) {
      this.selectedTransaction = transaction;
      this.showVoidModal = true;
    },
    async onVoid() {
      this.voidError = null;
      this.isVoiding = true;
      const result = await voidCoinFlushForm({
        id: this.selectedTransaction.id,
      });
      if (result.success) {
        this.loadData();
      } else {
        this.voidError = result.payload;
      }
      this.isVoiding = false;
      this.showVoidModal = false;
    },
  },
};
</script>
